import jQuery from 'jquery'
window.jQuery = jQuery
window.$ = jQuery

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
// import router from './router'
// import store from './store'
import 'animate.css'

Vue.config.productionTip = false

new Vue({
  // router,
  // store,
  render: h => h(App)
}).$mount('#app')
