<style lang="scss">
  @import './styles/index.scss';
  .background {
    z-index: 0 !important;
  }
</style>

<template>
  <div id="app" :style="{ zoom: zoom }">
    <div class="background">
      <img src="./assets/background.png" alt="background" />
    </div>
    <header class="header">
      <div class="header__container container">
        <div @click.prevent="scroll('top')" class="header__logo">
          <img
            src="./assets/Aiface.svg"
            width="100"
            height="100"
            alt="AiFace | 次世代クラウドホテル管理システム"
          />
        </div>
        <transition name="right">
          <nav v-show="navShow || mode === 'pc'" class="header__nav">
            <ul class="header__list mful">
              <li v-if="mode === 'sp'" class="header__item">
                <a
                  href=""
                  @click.prevent="scroll('top')"
                  :class="{ active: currentElem === 'top' }"
                  class="header--link"
                  >トップ</a
                >
              </li>
              <li class="header__item">
                <a
                  href=""
                  @click.prevent="scroll('about')"
                  :class="{ active: currentElem === 'about' }"
                  class="header--link"
                  >AiFaceとは？</a
                >
              </li>
              <li class="header__item after">
                <a
                  href=""
                  @click.prevent="scroll('pms')"
                  :class="{ active: currentElem === 'pms' }"
                  class="header--link"
                  >この時代のPMS</a
                >
              </li>
              <li class="header__item">
                <a
                  href=""
                  @click.prevent="scroll('merit')"
                  :class="{ active: currentElem === 'merit' }"
                  class="header--link"
                  >導入のメリット</a
                >
              </li>
              <li class="header__item">
                <a
                  href=""
                  @click.prevent="scroll('chara')"
                  :class="{ active: currentElem === 'chara' }"
                  class="header--link"
                  >主な特徴</a
                >
              </li>
              <li class="header__item">
                <a
                  href=""
                  @click.prevent="scroll('use')"
                  :class="{
                    active: currentElem === 'use' || currentElem === 'fig',
                  }"
                  class="header--link"
                  >ご利用について</a
                >
              </li>
              <!-- <li class="header__item">
                <a href="" @click.prevent="scroll('price')" :class="{active: currentElem==='price'}" class="header--link">料金表</a>
              </li> -->
              <li class="header__item">
                <a
                  href=""
                  @click.prevent="scroll('contact')"
                  :class="{ active: currentElem === 'contact' }"
                  class="header--link"
                  >お問合せ先</a
                >
              </li>
            </ul>
          </nav>
        </transition>

        <!-- ハンバーガー -->
        <div @click="openModal" class="menu-trigger active">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <!-- ハンバーガー閉じる -->
        <div v-show="navShow" @click="closeModal" class="menu-trigger-close">
          <span></span>
          <span></span>
        </div>
      </div>
    </header>

    <!-- scroll icon -->
    <div @click="scrollNext" class="scroll pc">
      <p class="scroll__txt">Scroll</p>
      <span class="scroll__block"></span>
    </div>

    <!-- scroll top icon -->
    <transition name="fade">
      <div
        @click="scroll('top')"
        v-show="currentElem !== 'top'"
        class="scrollTop"
      >
        <img
          class="scrollTop__arrow"
          src="./assets/Arrow.svg"
          alt="トップに戻る"
        />
      </div>
    </transition>

    <!-- 1 -->
    <section id="top" class="top section">
      <div class="top__container">
        <h1 class="top__title mmr">
          <div class="top__title--3">
            次世代クラウド<br class="sp" />ホテル管理システム
          </div>
        </h1>
        <div class="mfd">
          <img
            src="./assets/top_logo_aiface.svg"
            alt="AiFace"
            style="height: 151px"
          />
        </div>
        <div class="mfd">
          <p style="margin: 15px 0 15px 0; font-size: 14px">エーアイフェイス</p>
        </div>
        <div class="rectangle mfd">
          <p style="font-size: 180%">
            月額1室当たり800円<span class="no_tax">(税別)</span> ～
          </p>
        </div>
        <div class="mfd" style="margin-top: 15px ">
          <p style="font-size: 150%">上限金額：月額10万円(税別)</p>
        </div>

        <!-- <div class="bubble1_1">
          <p>実質基本料</p>
        </div> -->
        <div class="top__view mfd">
          <p class="cooperation">“ 連携してより使いやすく”</p>
          <span class="corner corner-t corner-r"></span>
          <span class="corner corner-t corner-l"></span>
          <span class="corner corner-b corner-r"></span>
          <span class="corner corner-b corner-l"></span>

          <!-- <p class="top__vtitle">
            全て<span class="top__vtitle--l">0</span>円
          </p>
          <ul class="top__vlist mful">
            <li class="top__vitem">初期基本設定費用</li>
            <li class="top__vitem">月額利用料</li>
            <li class="top__vitem">アップデート費用</li>
          </ul> -->
          <!-- <p class="top--vtitle">
            <span class="top__vtitle--l">0</span>円
          </p> -->

          <!-- <img src="./assets/logo_rc-and-be.png" alt="">
          <p class="top__txt">
            クチコミ管理分析、価格調査の<span class="ul">RepChecker(レップチェッカー)</span>や<br />
            無料予約エンジン<span class="ul">RCBooking</span>をご利用いただくことが条件となります。
          </p> -->
          <table>
            <tr>
              <!-- RC,+,Booking画像埋め込む -->
              <td>
                <a
                  href="https://repchecker.jp"
                  style="text-decoration: none"
                  target="_blank"
                >
                  <img src="./assets/top_logo_rc.svg" alt="RepChecker" />
                </a>
              </td>
              <td class="plus">＋</td>
              <!-- <td><img src="./assets/plus.png" alt="+" style="height: 15px"></td> -->
              <td>
                <a
                  href="https://www.rc-booking.com"
                  style="text-decoration: none"
                  target="_blank"
                >
                  <img src="./assets/top_logo_be.svg" alt="RCBooking" />
                </a>
              </td>
            </tr>
            <tr>
              <td class="rc_txt" valign="top">
                <div>
                  クチコミ管理分析、価格調査
                </div>
                <div>
                  <a
                    href="https://repchecker.jp"
                    style="text-decoration: underline"
                    target="_blank"
                  >
                    RepChecker(レップチェッカー)
                  </a>
                </div>
              </td>
              <td></td>
              <td class="rc_txt" valign="top">
                無料予約エンジン
                <a
                  href="https://www.rc-booking.com"
                  style="text-decoration: underline"
                  target="_blank"
                >
                  RCBooking
                </a>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </section>

    <!-- 2 -->
    <section id="about" class="about section">
      <div class="about__container container">
        <div class="about__titlearea titlearea mfu">
          <h2 class="about__title section-title">
            <img class="pc" src="./assets/title-1.svg" alt="AiFaceとは" />
            <img
              class="sp"
              src="./assets/what-is-aiface.svg"
              alt="AiFaceとは"
            />
          </h2>
        </div>
        <div class="about__txt">
          <p class="about__txt--1 mmr">
            AiFaceは最新機能を搭載した進化する完全クラウド型PMS<span
              class="about__txt--1b"
              >※1</span
            >です。
          </p>
          <p class="about__txt--2 mmr" style="font-size: 18px">
            コロナ禍の逆風に立ち向かうホテル、旅館業を応援するため、1000室規模の大型施設での運用をベースとした“高機能”クラウド型PMSを安価で提供、且つ無償アップデートで導入しやすさを追求したホテル管理システムです。
          </p>
          <p class="about__txt--2 mmr" style="font-size: 18px">
            今後必要となる多次元的かつ立体的なマーケティング戦略の支援をRepCheckerやRCBookingなどの弊社のサービスと連携してご提供することによって、宿泊施設の生産性向上と収益の最大化を実現します。
          </p>
          <div class="about__caution mmr">
            <p class="about__txt--3">
              ホテル管理システム(PMS)とは、Property Management
              Systemの略語です。ホテルや旅館などの宿泊施設の管理システムのことで、一般的には宿泊部門の管理システムを指します。
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- 3 -->
    <section id="pms" class="pms section">
      <div class="pms__container">
        <div class="pms__titlearea titlearea mfu">
          <h2 class="about__title section-title">
            <img class="pc" src="./assets/title-2.svg" alt="この時代のPMS" />
            <img
              class="sp"
              src="./assets/current-pms.svg"
              alt="この時代のPMS"
            />
          </h2>
        </div>
        <div class="pms__txt">
          <div class="pms__txt--top mmr">
            <p class="pms__txt--1">
              システムに働き方を合わせる時代がやってきました。
            </p>
            <p class="pms__txt--2">
              私たちは、ウィズコロナ、アフターコロナ時代のPMSを次のように考えました。
            </p>
            <span class="line-1"></span>
            <span class="line-2"></span>
            <span class="line-3"></span>
          </div>
          <div class="pms__txtbtm">
            <ul class="pms__list mful">
              <li class="pms__item">
                <div class="pms__box">
                  <p class="pms__num">1</p>
                  <span class="slash"></span>
                </div>
                <div class="pms__detail">
                  <h3 class="pms__dtitle">
                    最新機能へ定期的な<br class="sp" />無償アップデート
                  </h3>
                  <p class="pms__dtxt">
                    PMSを旅館・ホテル運営の基本インフラであると捉え、最新機能への定期的アップデートを無償で提供します。
                  </p>
                </div>
              </li>
              <li class="pms__item">
                <div class="pms__box">
                  <p class="pms__num">2</p>
                  <span class="slash"></span>
                </div>
                <div class="pms__detail">
                  <h3 class="pms__dtitle">カスタマイズ開発は最低限に留める</h3>
                  <p class="pms__dtxt">
                    標準を基本とし、各施設固有のカスタマイズ開発に伴うメンテナンスやシステム改修の費用を抑えます。
                  </p>
                </div>
              </li>
              <li class="pms__item">
                <div class="pms__box">
                  <p class="pms__num">3</p>
                  <span class="slash"></span>
                </div>
                <div class="pms__detail">
                  <h3 class="pms__dtitle">
                    収益アップのための連携と<br class="sp" />拡張性を持たせる
                  </h3>
                  <p class="pms__dtxt">
                    5G時代到来に向けて、IOTやRPAへの対応とAIを利用した収益アップへ、システムに拡張を持たせます。
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <!-- 4 -->
    <section id="merit" class="merit section">
      <div class="merit__container">
        <div class="merit__titlearea titlearea">
          <h2 class="about__title section-title mfu">
            <img class="pc" src="./assets/title-3.svg" alt="導入のメリット" />
            <img class="sp" src="./assets/merit.svg" alt="導入のメリット" />
          </h2>
        </div>
        <div class="merit__txtarea">
          <p class="merit__txt mmr">AiFaceを導入する3つのメリット</p>
          <ul class="merit__list mful">
            <li class="merit__item">
              <span class="line-1"></span>
              <span class="line-2"></span>
              <span class="line-3"></span>
              <span class="tri"></span>
              <p class="merit__num">1</p>
              <div class="merit__caption">
                <p class="merit__caption-txt" style="font-size: 26px">
                  1室当たり月額800円(税別)〜の<br
                    class="sp"
                  />リーズナブルな利用料
                </p>
              </div>
            </li>
            <li class="merit__item">
              <span class="line-1"></span>
              <span class="line-2"></span>
              <span class="line-3"></span>
              <span class="tri"></span>
              <p class="merit__num">2</p>
              <div class="merit__caption">
                <p class="merit__caption-txt" style="font-size: 26px">
                  最新機能を定期的に<br class="sp" />アップデート
                </p>
              </div>
            </li>
            <li class="merit__item">
              <span class="line-1"></span>
              <span class="line-2"></span>
              <span class="line-3"></span>
              <span class="tri"></span>
              <p class="merit__num">3</p>
              <div class="merit__caption mc3">
                <div class="merit__caption-block plus">
                  <p class="merit__caption-title">無料予約エンジン</p>
                  <div class="merit__caption-image-rcb">
                    <img src="./assets/rcb-logo.svg" alt="RCBooking" />
                  </div>
                </div>
                <div class="merit__caption-block plus">
                  <p class="merit__caption-title">＋</p>
                </div>
                <div class="merit__caption-block">
                  <p class="merit__caption-title">マーケティングクラウド</p>
                  <div class="merit__caption-image-rc">
                    <img src="./assets/rc-logo.svg" alt="RepChecker" />
                  </div>
                </div>
                <div class="merit__caption-block">
                  <p class="merit__caption-title">と連携</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <!-- 5 -->
    <section id="chara" class="chara section">
      <div class="chara__container">
        <div class="chara__titlearea titlearea mfu">
          <h2 class="about__title section-title">
            <img class="pc" src="./assets/title-4.svg" alt="主な特徴" />
            <img class="sp" src="./assets/main-feature.svg" alt="主な特徴" />
          </h2>
        </div>
        <div class="chara__txtarea">
          <p class="chara__txt mmr">
            不満が多かった<br class="sp" /><span class="chara__txt--l"
              >ホテルシステムの民主化</span
            >を<br
              class="sp"
            />低コストで実現し、<br />宿泊施設様の生産性向上<br
              class="sp"
            />に貢献します！
          </p>
          <div class="chara__garea">
            <div class="chara__graph mfd">
              <img src="./assets/graph.png" alt="graph" />
              <span class="chara__cir pc"></span>
              <span class="chara__cir pc"></span>
              <span class="chara__cir pc"></span>
              <span class="chara__cir pc"></span>
              <span class="chara__cir pc"></span>
            </div>
            <ul class="chara__list mful">
              <li class="chara__item item1">
                <p class="chara__desc">
                  <span class="chara__desc-c1 sp"></span>
                  <span class="chara__desc-c2 sp"></span>
                  <span class="chara__desc-line sp"></span>
                  <span class="chara__desc--l">1</span>クラウド型PMS
                </p>
              </li>
              <li class="chara__item  item2">
                <p class="chara__desc">
                  <span class="chara__desc-c1 sp"></span>
                  <span class="chara__desc-c2 sp"></span>
                  <span class="chara__desc-line sp"></span>
                  <span class="chara__desc--l">2</span>アクセス制限機能で<br
                    class="sp"
                  />安心のセキュリティ
                </p>
              </li>
              <li class="chara__item  item3">
                <p class="chara__desc">
                  <span class="chara__desc-c1 sp"></span>
                  <span class="chara__desc-c2 sp"></span>
                  <span class="chara__desc-line sp"></span>
                  <span class="chara__desc--l">3</span>AWS利用による<br
                    class="sp"
                  />安定稼働
                </p>
              </li>
              <li class="chara__item  item4">
                <p class="chara__desc">
                  <span class="chara__desc-c1 sp"></span>
                  <span class="chara__desc-c2 sp"></span>
                  <span class="chara__desc-line sp"></span>
                  <span class="chara__desc--l">4</span>タブレット連携<br
                    class="sp"
                  />ペーパーレス運用
                </p>
              </li>
              <li class="chara__item  item5">
                <p class="chara__desc">
                  <span class="chara__desc-c1 sp"></span>
                  <span class="chara__desc-c2 sp"></span>
                  <span class="chara__desc--l">5</span>先進的なUIデザイン<br
                    class="sp"
                  />と操作性
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <!-- 6 -->
    <section id="use" class="use section">
      <div class="use__container container">
        <h2 class="use__title mfu">AiFaceご利用について</h2>
        <!-- <div class="use__caption">
          <p class="use__ctxt mmr">
            AiFaceを無料でご利用いただくには、「RepChecker」と「RCBooking」のご利用が必要になります。
          </p>
        </div> -->
        <div class="use__detail mfu" style="margin-top: 60px">
          <ul class="use__list mful">
            <li class="use__item">
              <span class="use__item-n">1.</span>
              カスタマイズ開発は、有料となります。汎用的な機能で実現できない場合は、お受けできないことがあります。
            </li>
            <li class="use__item">
              <span class="use__item-n">2.</span>
              固定のグローバルIPアドレスをご準備ください。
            </li>
            <li class="use__item">
              <span class="use__item-n">3.</span>
              サイトコントローラーの接続料は別途必要になります。
            </li>
            <li class="use__item">
              <span class="use__item-n">4.</span
              >利用約款をご確認の上、ご承諾ください。
            </li>
          </ul>
        </div>
        <!-- <p class="use__captions mfu">
          ※ 2年目以降のデータ保持については、別途データ保管料がかかります。
        </p> -->
      </div>
    </section>

    <!-- 7 -->
    <section id="fig" class="fig section">
      <div class="fig__container container">
        <div class="fig__figre mmr">
          <img src="./assets/FIGURE.svg" alt="ご利用について" />
        </div>
        <!-- <p class="fig__btxt">
          ※1　2年目以降のデータ保持については、別途データ保管料がかかります。
        </p> -->
      </div>
    </section>

    <!-- 8 -->
    <!-- <section id="price" class="price section">
      <div class="price__container container">
        <h2 class="price__title mfu">
          AiFace基本利用料<span class="price__title--s">(税別)</span>
        </h2>
        <p class="price__cap mfu">
          RepCheckerやRC Bookingをご利用いただけない場合の料金メニューです。
        </p>
        <table class="pc mmr">
          <thead>
            <tr>
              <th class="ta-title" width="20%">客室数</th>
              <td align="center">1〜49</th>
              <td align="center">50〜99</th>
              <td align="center">100〜199</th>
              <td align="center">200〜299</th>
              <td align="center">300〜399</th>
              <td align="center">400〜499</th>
              <td align="center">500〜599</th>
              <td align="center">600〜</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="ta-title">初期設定費用<span>※1</span></td>
              <td align="center">130万円</td>
              <td align="center">140万円</td>
              <td align="center">160万円</td>
              <td align="center">210万円</td>
              <td align="center">260万円</td>
              <td align="center">310万円</td>
              <td align="center">360万円</td>
              <td align="center">要相談</td>
            </tr>
            <tr>
              <td class="ta-title">年間利用料</td>
              <td align="center">150万円</td>
              <td align="center">180万円</td>
              <td align="center">300万円</td>
              <td align="center">360万円</td>
              <td align="center">450万円</td>
              <td align="center">540万円</td>
              <td align="center">630万円</td>
              <td align="center">要相談</td>
            </tr>
            <tr>
              <td class="ta-title">初年度合計金額</td>
              <td align="center">280万円</td>
              <td align="center">320万円</td>
              <td align="center">460万円</td>
              <td align="center">570万円</td>
              <td align="center">710万円</td>
              <td align="center">850万円</td>
              <td align="center">990万円</td>
              <td align="center">要相談</td>
            </tr>
            <tr>
              <td class="ta-title">無料トレーニング日数</td>
              <td align="center">2日</td>
              <td align="center">2日</td>
              <td align="center">3日</td>
              <td align="center">3日</td>
              <td align="center">4日</td>
              <td align="center">4日</td>
              <td align="center">5日</td>
              <td align="center">6日〜</td>
            </tr>
          </tbody>
        </table>
        <table class="sp mmr">
          <tbody>
            <tr>
              <th class="ta-title" colspan="3">客室数</th>
            </tr>
            <tr>
              <th>1〜49</th>
              <th>50〜99</th>
              <th>100〜199</th>
            </tr>
            <tr>
              <th class="ta-title" colspan="3">初期設定費用<span>※1</span></th>
            </tr>
            <tr>
              <td>130万円</td>
              <td>140万円</td>
              <td>160万円</td>
            </tr>
            <tr>
              <th class="ta-title" colspan="3">年間利用料</th>
            </tr>
            <tr>
              <td>150万円</td>
              <td>180万円</td>
              <td>300万円</td>
            </tr>
            <tr>
              <th class="ta-title" colspan="3">初年度合計金額</th>
            </tr>
            <tr>
              <td>280万円</td>
              <td>320万円</td>
              <td>460万円</td>
            </tr>
            <tr>
              <th class="ta-title" colspan="3">無料トレーニング日数</th>
            </tr>
            <tr>
              <td>2日</td>
              <td>2日</td>
              <td>3日</td>
            </tr>
          </tbody>
        </table>
        <table class="sp mmr">
          <tbody>
            <tr>
              <th class="ta-title" colspan="3">客室数</th>
            </tr>
            <tr>
              <th>200〜299</th>
              <th>300〜399</th>
              <th>400〜499</th>
            </tr>
            <tr>
              <th class="ta-title" colspan="3">初期設定費用<span>※1</span></th>
            </tr>
            <tr>
              <td>210万円</td>
              <td>260万円</td>
              <td>310万円</td>
            </tr>
            <tr>
              <th class="ta-title" colspan="3">年間利用料</th>
            </tr>
            <tr>
              <td>360万円</td>
              <td>450万円</td>
              <td>540万円</td>
            </tr>
            <tr>
              <th class="ta-title" colspan="3">初年度合計金額</th>
            </tr>
            <tr>
              <td>570万円</td>
              <td>710万円</td>
              <td>850万円</td>
            </tr>
            <tr>
              <th class="ta-title" colspan="3">無料トレーニング日数</th>
            </tr>
            <tr>
              <td>3日</td>
              <td>4日</td>
              <td>4日</td>
            </tr>
          </tbody>
        </table>
        <table class="sp mmr">
          <tbody>
            <tr>
              <th class="ta-title" colspan="3">客室数</th>
            </tr>
            <tr>
              <th>500〜599</th>
              <th>600〜</th>
              <th></th>
            </tr>
            <tr>
              <th class="ta-title" colspan="3">初期設定費用<span>※1</span></th>
            </tr>
            <tr>
              <td>360万円</td>
              <td>要相談</td>
              <td></td>
            </tr>
            <tr>
              <th class="ta-title" colspan="3">年間利用料</th>
            </tr>
            <tr>
              <td>630万円</td>
              <td>要相談</td>
              <td></td>
            </tr>
            <tr>
              <th class="ta-title" colspan="3">初年度合計金額</th>
            </tr>
            <tr>
              <td>990万円</td>
              <td>要相談</td>
              <td></td>
            </tr>
            <tr>
              <th class="ta-title" colspan="3">無料トレーニング日数</th>
            </tr>
            <tr>
              <td>5日</td>
              <td>6日〜</td>
              <td></td>
            </tr>
          </tbody>
        </table>


        <table class="mmr">
          <thead>
            <tr>
              <th>※１初期設定費用に含まれる内容</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="sp-left">
                <span>● キーカードインターフェース開発</span><br class="sp" />
                <span>● Folio（領収書）フォーマット</span><br class="sp" />
                <span>● トレーニング<br class="sp" />（客室数別 2〜5日 / 1日あたり4時間）</span>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="mmr">
          <thead>
            <tr>
              <th>別途お見積もりによる開発費用等</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="price__blockarea sp-left">
                <div class="price__block">
                  <span class="price__txt">● レポートカスタマイズ</span>
                  <span class="price__txt">● 朝食券システム</span>
                  <span class="price__txt">● 清掃システム</span>
                </div>
                <div class="price__block">
                  <span class="price__txt"
                    >● 追加トレーニング：1日 4時間 / 8万円（税別）</span
                  >
                  <span class="price__txt"
                    >● トレーニングのための交通費 / 宿泊費</span
                  >
                </div>
                <div class="price__block">
                  <span class="price__txt">● 会計ソフト連携</span>
                  <span class="price__txt"
                    >● その他外部機器等とのインターフェース開発</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section> -->

    <!-- 9 -->
    <section id="contact" class="contact section">
      <div class="contact__container container mfu">
        <div class="contact__block">
          <div class="contact__logoarea">
            <img src="./assets/ps-logo.svg" alt="ParadimeShift" />
            <p class="contact__logotxt">
              IT・クラウドサービスで宿泊産業<br
                class="sp"
              />の未来化を​創造する。
            </p>
          </div>
        </div>
        <div class="contact__block">
          <div class="contact__txtarea">
            <h2 class="contact__company">株式会社パラダイムシフト</h2>
            <p class="contact__address">〒103-0004 東京都中央区東日本橋1-3-6</p>
            <a
              href="https://paradigmshift.io"
              target="_blank"
              class="contact__url"
              >https://paradigmshift.io</a
            >
            <p class="contact__telemail">
              TEL:03-5825-9970 / Email:sales@aiface.jp
            </p>
            <a
              href="https://contact.psinc.jp"
              target="_blank"
              class="contact__btn"
            >
              <div class="contact__img">
                <img src="./assets/contact-icon.svg" alt="お問い合わせ" />
              </div>
              お問い合わせ
            </a>
          </div>
        </div>
      </div>
    </section>
    <div
      id="loader-bg"
      style="
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #11061b;
    z-index: 99999;
    "
    ></div>
  </div>
</template>

<script>
  export default {
    name: 'App',

    data: () => ({
      mode: '',
      zoom: 1,
      elems: [
        'top',
        'about',
        'pms',
        'merit',
        'chara',
        'use',
        'fig',
        'price',
        'contact',
      ],
      currentElem: 'top',
      navShow: false,
    }),

    mounted() {
      window.addEventListener('load', this.getMode)
      window.addEventListener('resize', this.setZoom)
      window.addEventListener('scroll', this.getCurrentPositon)
    },

    methods: {
      getMode() {
        let w = window.innerWidth
        if (w >= 767) {
          this.mode = 'pc'
        } else {
          this.mode = 'sp'
        }
      },
      setZoom() {
        let w = window.innerWidth
        if (w <= 1500 && w >= 767) {
          this.zoom = w / 1500
        } else if (w < 767) {
          this.zoom = w / 375
        } else {
          this.zoom = 1
        }
        if (w >= 767) {
          this.mode = 'pc'
        } else {
          this.mode = 'sp'
        }
      },
      getCurrentPositon() {
        const elemList = this.elems.map((e) => {
          const elem = document.getElementById(e)
          const elemTop = elem.getBoundingClientRect().top
          return Math.abs(elemTop)
        })
        const currentElem = elemList.indexOf(Math.min(...elemList))
        this.currentElem = this.elems[currentElem]
      },
      scroll(id) {
        if (this.navShow) {
          this.navShow = false
        }
        this.currentElem = id
        const elem = document.getElementById(id)
        elem.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      },
      scrollNext() {
        const elemList = this.elems.map((e) => {
          const elem = document.getElementById(e)
          const elemTop = elem.getBoundingClientRect().top
          return Math.abs(elemTop)
        })
        const currentElem = elemList.indexOf(Math.min(...elemList))
        const nextElem = document.getElementById(this.elems[currentElem + 1])
        nextElem.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
        this.currentElem = this.elems[currentElem + 1]
      },
      openModal() {
        this.navShow = true
      },
      closeModal() {
        this.navShow = false
      },
    },
  }
</script>
